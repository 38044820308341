* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input {
  color: black;
}


.g4-logo-open img {
  width: 100px;
}

#chat-g4{
  font-family: Roboto;
  font-size: 12px;
}

#chat-g4 h4 {
  font-family: Roboto, sans-serif;
}

 #input-chat-g4 {
  font-family: Roboto, sans-serif;
  font-size: 12px;
}

#text_G4_flex {
  font-size: 15px;
  font-family: Raleway;
  word-wrap: break-word;
  margin-right: 30px;
  white-space: pre-wrap;
}


