

input {
  color: black;
}

.g4-select-customer-experience {
  margin-top: 10px;
  padding: 5px;
  border-radius: 5px;
}

.g4-select-customer-experience:focus {
  outline: 0;
}
