.loading-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
  }
  
  .loading-dots div {
    width: 8px;
    height: 8px;
    margin: 0 4px;
    background-color: #333;
    border-radius: 50%;
    animation: loading-dots 1.4s infinite ease-in-out both;
  }
  
  .loading-dots div:nth-child(1) {
    animation-delay: -0.32s;
  }
  
  .loading-dots div:nth-child(2) {
    animation-delay: -0.16s;
  }
  
  @keyframes loading-dots {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }